*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Akaya Telivigala', cursive;
  font-family: 'Sora', sans-serif;
  overflow-x: hidden;
}

h1,
h1,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
